import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DoneIcon from '@mui/icons-material/Done';
import Alert from '@mui/material/Alert';

import CloseIcon from '@mui/icons-material/Close';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';

import { useQueryParam } from 'gatsby-query-params';

import * as dataApi from '../api/dataApi';
import * as offerCalc from '../api/offerCalc';
import SortIcon from '../components/OfferGrid/SortIcon';
import OfferRow from '../components/OfferGrid/OfferRow';

import '../components/OfferGrid/index.css';

function OfferGrid({ serviceAddress, webSocket }) {
  const headerRef = useRef();
  const observer = useRef();
  const [headerIsSticky, setHeaderIsSticky] = useState(false);

  const requestProviderServiceList = useQueryParam('services', '');

  const [sortColumn, setSortColumn] = useState('PriceDollars');
  const [rawOfferList, setRawOfferList] = useState(null);
  const [offerList, setOfferList] = useState(null);
  const [filteredOfferList, setFilteredOfferList] = useState(null);
  const [offerDictionary, setOfferDictionary] = useState({});
  const serviceFilterIsInclusive = false;
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [selectedProviderList, setSelectedProviderList] = useState(null);
  const [selectedTermLength, setSelectedTermLength] = useState(null);
  const [quickFilter, setQuickFilter] = useState(
    requestProviderServiceList ? requestProviderServiceList : null
  );
  const offerCalcuationTimeout = useRef();
  const [offersUpdating, setOffersUpdating] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState(null);

  const [allOptionSelected, setAllOptionSelected] = useState({
    rateType: true,
    termLength: true,
    provider: true
  });
  const [filterCounts, setFilterCounts] = useState({});

  useEffect(() => {
    if (serviceAddress && serviceAddress.OfferDictionary) {
      let _offerList = [];

      for (const key in serviceAddress.OfferDictionary) {
        if (
          key.indexOf('Internet') !== -1 ||
          key.indexOf('TV') !== -1 ||
          key.indexOf('Phone') !== -1 ||
          key.indexOf('Home Security') !== -1 ||
          key.indexOf('Satellite') !== -1
        ) {
          _offerList = _offerList.concat(serviceAddress.OfferDictionary[key]);
        }

        setRawOfferList(_offerList);
      }
    }
  }, [serviceAddress]);

  useEffect(() => {
    if (rawOfferList) {
      const visibleOfferList = rawOfferList
        .filter((offer) => offer.OfferTypeID === 1)
        .map((offer) => offerCalc.calcInternetOffer(rawOfferList, offer));

      if (offerCalc.checkOffersChanged(visibleOfferList, offerList)) {
        console.log('offers changed');
        setOfferList(visibleOfferList);
      }
    }
  }, [rawOfferList]);

  useEffect(() => {
    if (offerList) {
      let dictProviderService = {};
      let dictProvider = {};
      let dictTermLength = {};

      let providerServiceCount = 0;

      const servicesList =
        requestProviderServiceList === 'All' ? '' : requestProviderServiceList;

      const _requestProviderServiceList = servicesList
        ? String(servicesList).split(',')
        : [];

      offerList.map((offer) => {
        offer.ProviderServiceList.split(', ').map((providerServiceName) => {
          if (!dictProviderService[providerServiceName]) {
            if (_requestProviderServiceList.length) {
              dictProviderService[providerServiceName] =
                _requestProviderServiceList.filter(
                  (_providerServiceName) =>
                    _providerServiceName === providerServiceName
                ).length > 0;
            } else {
              dictProviderService[providerServiceName] = false;
            }

            providerServiceCount++;
          }
        });

        let providerFilter = dictProvider[offer.ProviderName]
          ? dictProvider[offer.ProviderName]
          : false;

        dictProvider[offer.ProviderName] = providerFilter;

        const termLengthLabel = getTermLengthLabel(offer);

        let termLengthFilter = dictTermLength[termLengthLabel]
          ? dictTermLength[termLengthLabel]
          : false;

        dictTermLength[termLengthLabel] = termLengthFilter;
      });

      setSelectedProviderList(dictProvider);
      setSelectedTermLength(dictTermLength);
    }
  }, [offerList, requestProviderServiceList]);

  useEffect(() => {
    updateOfferList('selectedProviderList changed');
  }, [selectedProviderList]);

  useEffect(() => {
    updateOfferList('selectedTermLength changed');
  }, [selectedTermLength]);

  useEffect(() => {
    updateOfferList('allOptionSelected changed');
  }, [allOptionSelected]);

  useEffect(() => {
    if (filteredOfferList) {
      doOfferSort(filteredOfferList);
    }
  }, [sortColumn]);

  useEffect(() => {}, [filteredOfferList]);

  useEffect(() => {
    for (const key in offerDictionary) {
      if (!offerDictionary[key].detailList) {
        dataApi.listOfferDetail(key).then((detailList) => {
          let _detailList = {};

          detailList.map((detail) => {
            _detailList[detail.Key] = detail.Value;
          });

          setOfferDictionary({
            ...offerDictionary,
            [key]: {
              ...offerDictionary[key],
              detailList: _detailList
            }
          });
        });
      }
    }
  }, [offerDictionary]);

  useEffect(() => {
    if (quickFilter != requestProviderServiceList && quickFilter) {
      navigate('/home-services?services=' + quickFilter);
    }

    if (quickFilter) {
      let _columnVisibility = {};
      _columnVisibility['PriceDollars'] = true;
      _columnVisibility['TermLength'] = true;

      switch (quickFilter) {
        case 'Internet,TV':
          _columnVisibility['InternetDownloadSpeedMb'] = true;
          _columnVisibility['TVChannelCount'] = true;
        case 'Internet':
        case 'Satellite':
          _columnVisibility['InternetDownloadSpeedMb'] = true;
          break;
        case 'TV':
          _columnVisibility['TVChannelCount'] = true;
          break;
        case 'All':
          _columnVisibility['InternetDownloadSpeedMb'] = true;
          _columnVisibility['TVChannelCount'] = true;
          break;
      }

      setColumnVisibility(_columnVisibility);
    }
  }, [quickFilter]);

  useEffect(() => {
    navigate('/home-services?services=' + requestProviderServiceList);
    setQuickFilter(
      requestProviderServiceList ? requestProviderServiceList : 'Internet,TV'
    );
  }, [requestProviderServiceList]);

  useEffect(() => {
    if (headerRef.current && !observer.current) {
      observer.current = new IntersectionObserver(
        ([e]) => {
          setHeaderIsSticky(e.intersectionRatio < 1);
        },
        { threshold: [1] }
      );

      observer.current.observe(headerRef.current);

      console.log('add observer');
    }
  }, [headerRef.current]);

  useEffect(() => {
    // unmount header observer
    return function () {
      if (observer.current && headerRef.current) {
        console.log('remove observer');
        observer.current.unobserve(headerRef.current);
        observer.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (allOptionSelected.termLength && selectedTermLength) {
      let _selectedTermLength = { ...selectedTermLength };
      let someTermLengthChanged = false;
      Object.keys(_selectedTermLength).map((key) => {
        if (_selectedTermLength[key]) {
          _selectedTermLength[key] = false;
          someTermLengthChanged = true;
        }
      });

      if (someTermLengthChanged) {
        setSelectedTermLength({ ..._selectedTermLength });
      }
    }

    if (allOptionSelected.provider && selectedProviderList) {
      let _selectedProviderList = { ...selectedProviderList };
      let someProviderChanged = false;
      Object.keys(_selectedProviderList).map((key) => {
        if (_selectedProviderList[key]) {
          _selectedProviderList[key] = false;
          someProviderChanged = true;
        }
      });

      if (someProviderChanged) {
        setSelectedProviderList({ ..._selectedProviderList });
      }
    }
  }, [allOptionSelected]);

  useEffect(() => {
    if (selectedTermLength) {
      let someTermLengthSelected = false;
      Object.keys(selectedTermLength).map((key) => {
        if (selectedTermLength[key]) {
          someTermLengthSelected = true;
        }
      });

      setAllOptionSelected({
        ...allOptionSelected,
        termLength: !someTermLengthSelected
      });
    }

    updateFilterCounts();
  }, [selectedTermLength]);

  useEffect(() => {
    if (selectedProviderList) {
      let someProviderSelected = false;
      Object.keys(selectedProviderList).map((key) => {
        if (selectedProviderList[key]) {
          someProviderSelected = true;
        }
      });

      setAllOptionSelected({
        ...allOptionSelected,
        provider: !someProviderSelected
      });
    }
  }, [selectedProviderList]);

  function updateOfferList(changeEvent) {
    setOffersUpdating(true);

    if (offerCalcuationTimeout.current) {
      clearTimeout(offerCalcuationTimeout.current);
    }

    offerCalcuationTimeout.current = setTimeout(() => {
      if (offerList && offerList.length) {
        doOfferSort(
          offerList.filter((offer) => {
            let passesFilter = selectedProviderList
              ? (selectedProviderList[offer.ProviderName] &&
                  selectedProviderList[offer.ProviderName] === true) ||
                allOptionSelected.provider
              : true;

            const termLengthLabel = getTermLengthLabel(offer);

            if (!allOptionSelected.termLength) {
              if (selectedTermLength && !selectedTermLength[termLengthLabel]) {
                passesFilter = false;
              }
            }

            if (!passesQuickFilter(offer)) {
              passesFilter = false;
            }

            return passesFilter;
          })
        );

        setOffersUpdating(false);
      }

      updateFilterCounts();
    }, 200);
  }

  function passesQuickFilter(offer) {
    let passesFilter = true;

    switch (quickFilter) {
      case 'Internet':
      case 'TV':
      case 'Satellite':
        if (offer.ProviderServiceList !== quickFilter) {
          passesFilter = false;
        }
        break;
      case 'Internet,TV':
        if (
          offer.ProviderServiceList.indexOf('Internet') === -1 ||
          offer.ProviderServiceList.indexOf('TV') === -1
        ) {
          passesFilter = false;
        }
        break;
    }

    return passesFilter;
  }

  function getTermLengthLabel(offer) {
    return offer.TermTypeID == null
      ? 'Check with Provider'
      : offer.TermTypeID === 1 && offer.TermLength && offer.TermLength > 1
      ? offer.TermLength + ' months'
      : 'No Contract';
  }

  function getTermLengthKeys() {
    let termLengthList = Object.keys(selectedTermLength);

    termLengthList.sort((a, b) => {
      let result = a > b ? 1 : a < b ? -1 : 0;

      if (a === 'Monthly') {
        result = -1;
      }

      return result;
    });

    return termLengthList;
  }

  function updateFilterCounts() {
    if (selectedTermLength && selectedProviderList) {
      let dictProvider = {};
      for (const providerName in selectedProviderList) {
        dictProvider[providerName] = 0;
      }

      let dictTermLength = {};
      for (const termLength in selectedTermLength) {
        dictTermLength[termLength] = 0;
      }

      offerList.map((offer) => {
        let passesTermLength = true;
        let passesProvider = true;

        const termLengthLabel = getTermLengthLabel(offer);
        if (!allOptionSelected.termLength) {
          if (
            selectedTermLength &&
            selectedTermLength[termLengthLabel] !== true
          ) {
            passesProvider = false;
          }
        }

        if (!allOptionSelected.provider) {
          if (
            selectedProviderList &&
            selectedProviderList[offer.ProviderName] !== true
          ) {
            passesTermLength = false;
          }
        }

        if (!passesQuickFilter(offer)) {
          passesTermLength = false;
          passesProvider = false;
        }

        if (passesTermLength) {
          dictTermLength[termLengthLabel]++;
        }

        if (passesProvider) {
          dictProvider[offer.ProviderName]++;
        }
      });

      setFilterCounts({
        ...filterCounts,
        termLength: dictTermLength,
        provider: dictProvider
      });
    }
  }

  function sortOffers(column) {
    setSortColumn(sortColumn === column ? '-' + column : column);
  }

  function doOfferSort(offerList) {
    const column = sortColumn.split('-').join('');
    let desc = sortColumn.indexOf('-') === 0;

    let sortedList = offerList.concat([]);

    sortedList.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      let result = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;

      if (valueB === null) {
        result = -1;
      }

      if (valueA === null) {
        result = 1;
      }

      if (desc) {
        result = result * -1;
      }

      return result;
    });

    setFilteredOfferList(sortedList);
  }

  return (
    <>
      {columnVisibility ? (
        <div
          className={
            'page-container offer-grid-container internet' +
            (offersUpdating ? ' updating' : '')
          }>
          <h1>
            <span className="keep-together">
              Internet &amp; TV Offers in{' '}
              {serviceAddress &&
              serviceAddress.Address &&
              serviceAddress.Address.Zip
                ? serviceAddress.Address.Zip
                : 'Texas'}
            </span>

            <div className="quick-filters">
              <Tabs
                value={quickFilter}
                onChange={(event, newValue) => setQuickFilter(newValue)}
                indicatorColor="primary"
                textColor="primary">
                <Tab value="Internet,TV" label="Internet and TV" />
                <Tab value="Internet" label="Internet Only" />
                <Tab value="TV" label="TV Only" />
                <Tab value="Satellite" label="Satellite" />
                <Tab value="All" label="All Plans" />
              </Tabs>
            </div>
          </h1>
          <div className="page outer">
            {filteredOfferList ? (
              <div className="inner offer-grid internet">
                {quickFilter === 'Satellite' ? (
                  <Alert severity="info">
                    The Satellite and Fixed Base Wireless services below require
                    an outside, line of sight dish or antenna. If you are
                    renting, you should contact the landlord or leasing office
                    prior to placing an order to confirm availability.
                  </Alert>
                ) : null}
                <div
                  className={
                    'offer-grid-header' +
                    (filtersExpanded ? ' filters-open' : '')
                  }>
                  <Button
                    className="filters-button"
                    onClick={() => setFiltersExpanded(!filtersExpanded)}>
                    <FontAwesomeIcon icon={['fa', 'sliders-h']} /> Filters{' '}
                    <ExpandMoreIcon />
                  </Button>

                  {/* <div className="count">
                  Showing {filteredOfferList.length} of {offerList.length}{' '}
                  offers
                </div> */}

                  <div className="controls">
                    {selectedTermLength ? (
                      <div className="filters-set">
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            Contract term:
                          </FormLabel>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    allOptionSelected.termLength === true
                                  }
                                  onChange={() =>
                                    setAllOptionSelected({
                                      ...allOptionSelected,
                                      termLength: !allOptionSelected.termLength
                                    })
                                  }
                                  name="Show all"
                                  color="primary"
                                  disabled={allOptionSelected.termLength}
                                />
                              }
                              label="Show all"
                            />

                            {getTermLengthKeys().map((termLength) =>
                              filterCounts.termLength[termLength] ||
                              quickFilter === 'All' ? (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        !allOptionSelected.termLength &&
                                        selectedTermLength[termLength] === true
                                      }
                                      onChange={() =>
                                        setSelectedTermLength({
                                          ...selectedTermLength,
                                          [termLength]:
                                            selectedTermLength[termLength] ===
                                            true
                                              ? false
                                              : true
                                        })
                                      }
                                      name={termLength}
                                      color="primary"
                                    />
                                  }
                                  label={
                                    termLength +
                                    (filterCounts && filterCounts.termLength
                                      ? ' (' +
                                        filterCounts.termLength[termLength] +
                                        ')'
                                      : '')
                                  }
                                  disabled={
                                    !filterCounts ||
                                    !filterCounts.termLength ||
                                    !filterCounts.termLength[termLength]
                                  }
                                />
                              ) : null
                            )}
                          </FormGroup>
                        </FormControl>
                      </div>
                    ) : null}

                    {selectedProviderList ? (
                      <div className="filters-set provider">
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Provider:</FormLabel>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={allOptionSelected.provider === true}
                                  onChange={() =>
                                    setAllOptionSelected({
                                      ...allOptionSelected,
                                      provider: !allOptionSelected.provider
                                    })
                                  }
                                  name="Show all"
                                  color="primary"
                                  disabled={allOptionSelected.provider}
                                />
                              }
                              label="Show all"
                            />

                            {Object.keys(selectedProviderList).map(
                              (providerName) =>
                                filterCounts.provider[providerName] ||
                                quickFilter === 'All' ? (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          !allOptionSelected.provider &&
                                          selectedProviderList[providerName] ===
                                            true
                                        }
                                        onChange={() =>
                                          setSelectedProviderList({
                                            ...selectedProviderList,
                                            [providerName]:
                                              selectedProviderList[
                                                providerName
                                              ] === true
                                                ? false
                                                : true
                                          })
                                        }
                                        color="primary"
                                        name={providerName}
                                      />
                                    }
                                    label={
                                      providerName +
                                      (filterCounts && filterCounts.provider
                                        ? ' (' +
                                          filterCounts.provider[providerName] +
                                          ')'
                                        : '')
                                    }
                                    disabled={
                                      !filterCounts ||
                                      !filterCounts.provider ||
                                      !filterCounts.provider[providerName]
                                    }
                                  />
                                ) : null
                            )}
                          </FormGroup>
                        </FormControl>
                      </div>
                    ) : null}
                  </div>
                </div>

                <AppBar
                  position="sticky"
                  ref={headerRef}
                  className={headerIsSticky ? 'is-sticky' : ''}>
                  <div
                    className={
                      'offer-row header' +
                      (filtersExpanded ? ' filters-expanded' : '')
                    }>
                    <div className="brand">
                      <div className="content">
                        <span className="sort-label">Sort Offers:</span>
                      </div>
                    </div>

                    <div className="primary-data">
                      {columnVisibility['TermLength'] ? (
                        <div className="data-point">
                          <Button onClick={() => sortOffers('TermLength')}>
                            <SortIcon
                              sortColumn={sortColumn}
                              column="TermLength"
                            />
                            Term Type
                          </Button>
                        </div>
                      ) : null}
                      {columnVisibility['InternetDownloadSpeedMb'] ? (
                        <div className="data-point">
                          <Button
                            onClick={() =>
                              sortOffers('InternetDownloadSpeedMb')
                            }>
                            <SortIcon
                              sortColumn={sortColumn}
                              column="InternetDownloadSpeedMb"
                            />
                            Internet
                          </Button>
                        </div>
                      ) : null}
                      {columnVisibility['TVChannelCount'] ? (
                        <div className="data-point">
                          <Button onClick={() => sortOffers('TVChannelCount')}>
                            <SortIcon
                              sortColumn={sortColumn}
                              column="TVChannelCount"
                            />
                            TV
                          </Button>
                        </div>
                      ) : null}
                      {columnVisibility['PriceDollars'] ? (
                        <div className="data-point price">
                          <Button onClick={() => sortOffers('PriceDollars')}>
                            <SortIcon
                              sortColumn={sortColumn}
                              column="PriceDollars"
                            />
                            Price
                          </Button>
                        </div>
                      ) : null}
                    </div>
                    <div className="action">
                      <div className="content"></div>
                    </div>
                  </div>
                </AppBar>
                {filteredOfferList.map((offer) => (
                  <OfferRow
                    offer={offer}
                    offerDictionary={offerDictionary}
                    setOfferDictionary={setOfferDictionary}
                    columnVisibility={columnVisibility}
                  />
                ))}
              </div>
            ) : !webSocket || !webSocket.pageLoading ? (
              <div className="loading">
                <div className="loading">
                  <CircularProgress />
                  <br />
                  <br />
                  Loading plans...
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferGrid);
